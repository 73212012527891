<template>
    <div>                
      <div class="card shadow mb-4">
        <div class="card-header py-3">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-6">
              <h6 v-if="fastOpcoesBoleto.baixar" class="m-0 font-weight-bold text-primary">
                Baixar fatura
              </h6>
              <h6 v-else-if="fastOpcoesBoleto.detalhes" class="m-0 font-weight-bold text-primary">
                Detalhes fatura
              </h6>
              <h6 v-else class="m-0 font-weight-bold text-primary">
                Editar fatura
              </h6>
            </div>  
            <div class="col-sm-12 col-md-12 col-lg-6 text-right">
              <div v-if="fastErrosGerencianetLoading" class="text-warning d-flex align-items-center justify-content-end">                          
                  <b-icon icon="gear-fill" animation="spin" /> <span class="ml-2"> Verificando inconsistências</span>
              </div>
              <div v-else-if="fastErrosGerencianet.length">
                <a href="#" class="text-danger d-flex align-items-center justify-content-end" @click.prevent="showModal('modalErrosFaturaGateway')">
                  <b-icon icon="exclamation-circle-fill" variant="danger" font-scale="2" ></b-icon> <span class="ml-2">Atenção</span> 
                </a> 
              </div>
              <div v-else class="text-success d-flex align-items-center justify-content-end">
                <b-icon icon="check2-circle" /> <span class="ml-2"> Nenhuma inconsistência detectada</span>
              </div>
                                      
            </div>                                           
          </div>
        </div>
        <div class="card-body">
          <div class="col-12 mt-2">

            <div v-if="!fastOpcoesBoleto.baixar" class="row mt-4">    
              <div class="col-sm-12 col-md-12 col-lg-6 mb-0">
                <h4 class="font-weight-bold m-0 mb-2 text-dark">
                  <span>Invoice #{{fastTesourariaInvoice.id_invoice}}</span> 
                  <span v-if="fastTesourariaInvoice.id_matricula"> - Matrícula #{{fastTesourariaInvoice.id_matricula}}</span>
                </h4>

                  <div class="mt-3">
                    <label class="font-weight-bold text-success">Valor total do Invoice:</label>&nbsp;
                    <span class="font-weight-bold text-secondary">R$ {{ formataPreco(fastTesourariaInvoice.total) }}</span>
                    <span class="text-danger font-weight-bold"> {{retornaTotalComissao()}}</span> 
                  </div>
                  <div>
                    <span class="pr-2"><b-badge variant="success">Pago: R$ {{ formataPreco(fastTesourariaInvoice.totalPago) }}</b-badge></span>
                    <span class="pr-2"><b-badge variant="danger">Vencido: R$ {{ formataPreco(fastTesourariaInvoice.totalVencido) }}</b-badge></span>
                    <span class="pr-2"><b-badge class="text-light" variant="primary">Pendente: R$ {{ formataPreco(fastTesourariaInvoice.total - (fastTesourariaInvoice.totalPago + fastTesourariaInvoice.totalVencido + fastTesourariaInvoice.totalCancelado)) }}</b-badge></span>
                    <span class="pr-2"><b-badge variant="secondary">Cancelado: R$ {{ formataPreco(fastTesourariaInvoice.totalCancelado) }}</b-badge></span>
                  </div>
                  
                  <div v-if="fastTesourariaInvoice.motivo_cancelamento_invoice">
                    <label class="font-weight-bold text-danger">Motivo do cancelamento: </label>&nbsp; <span class="text-secondary">{{fastTesourariaInvoice.motivo_cancelamento_invoice}}</span>
                  </div>

              </div>

              <div class="col-sm-12 col-md-12 col-lg-6 mb-0 text-right">
                <div v-if="fastTesourariaInvoice.cancelado_invoice">
                  <h4 class="text-danger font-weight-bold">Invoice cancelada</h4>  
                </div>
                <div v-else>
                  <h4 class="text-success font-weight-bold mb-4">Invoice ativo</h4>   
                  <a v-if="!fastOpcoesBoleto.detalhes" href="#" class="btn btn-sm btn-danger" @click.prevent="showModal('modalCancelarInvoice')">
                    <b-icon icon="x-octagon"></b-icon> <span>Cancelar invoice</span>
                  </a>
                </div>
              </div>


              <div v-if="fastTesourariaInvoice.obs" class="col-sm-12 col-md-12 col-lg-12 mb-2">
                <label class="font-weight-bold text-secondary">Observação:</label>&nbsp;
                <span>{{ fastTesourariaInvoice.obs }}</span>
              </div>  
              <div class="col-sm-12 col-md-12 col-lg-2 text-center d-none">
                <label>Cancelado</label>
                <div class="fd-app-meus-cursos mt-0 ml-1">
                  <div class="fd-app-curso-aulas-list mt-0">
                    <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center">
                      <label class="switch">
                        <input
                          v-model="fastTesourariaInvoice.cancelado_invoice"
                          checked="checked"
                          type="checkbox"
                        >
                        <span class="slider round" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr v-if="!fastOpcoesBoleto.baixar" class="solid mt-4"/>

            <div class="row mt-4">

              <div class="col-sm-12 col-md-12 col-lg-6">
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                    <label class="font-weight-bold text-secondary">Aluno:</label>&nbsp; <span>{{fastTesourariaInvoice.nome_aluno}}</span>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                    <label class="font-weight-bold text-secondary">CPF do aluno:</label>&nbsp; <span>{{fastTesourariaInvoice.cpf_aluno}}</span>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                    <label class="font-weight-bold text-secondary">Telefone do aluno:</label>&nbsp; <span>{{fastTesourariaInvoice.aluno.telefone}}</span>
                    <a v-if="fastTesourariaInvoice.aluno.telefone" target="_blank" :href="retornaLinkWhats(fastTesourariaInvoice.aluno.telefone)" class="btn btn-sm btn-success pb-0 pt-0 ml-2">
                      <small><b-icon icon="share" aria-hidden="true"></b-icon> WhatsApp</small>                                
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-6">
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                    <label class="font-weight-bold text-secondary">Responsável:</label>&nbsp; <span>{{fastTesourariaInvoice.nome_responsavel}}</span>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                    <label class="font-weight-bold text-secondary">CPF do responsável:</label>&nbsp; <span>{{fastTesourariaInvoice.cpf_responsavel}}</span>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                    <label class="font-weight-bold text-secondary">Telefone do responsável:</label>&nbsp; <span>{{fastTesourariaInvoice.responsavel.telefone}}</span>
                    <a v-if="fastTesourariaInvoice.responsavel.telefone" target="_blank" :href="retornaLinkWhats(fastTesourariaInvoice.responsavel.telefone)" class="btn btn-sm btn-success pb-0 pt-0 ml-2">
                      <small><b-icon icon="share" aria-hidden="true"></b-icon> WhatsApp</small>                                
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
                <label class="font-weight-bold text-secondary">Origem:</label>&nbsp;
                <label v-if="fastTesourariaInvoice.tipo">
                  <span v-if="fastTesourariaInvoice.tipo == 'M'">Taxa de matrícula</span>
                  <span v-else-if="fastTesourariaInvoice.tipo == 'C'">Curso </span>
                  <span v-else-if="fastTesourariaInvoice.tipo == 'R'">Requerimento </span>
                  <span v-else-if="fastTesourariaInvoice.tipo == 'I'">Item de cobrança </span>
                  <span v-else-if="fastTesourariaInvoice.tipo == 'L'">Curso livre </span>
                </label>
                <span v-else>Curso</span>
                <span v-if="fastTesourariaInvoice.id_matricula"> - Matrícula #{{fastTesourariaInvoice.id_matricula}}</span>
              </div>

              <div v-if="!fastOpcoesBoleto.baixar" class="col-12 mt-3">
                <div class="row mb-4">
                  <div class="col-12 table-responsive">
                    <table class="table table-sm text-nowrap">
                      <thead class="thead-dark">
                        <tr>
                          <th class="text-center">
                            Parcela
                          </th>
                          <th class="text-center">
                            Emissão
                          </th>
                          <th class="text-center">
                            Vencimento
                          </th>
                          <th class="text-center">
                            Valor
                          </th>
                          <th class="text-center">
                            Juros
                          </th>
                          <th class="text-center">
                            Multa
                          </th>
                          <th class="text-center">
                            Tx Cartão
                          </th>
                          <th class="text-center">
                            Tx Boleto
                          </th>
                          <th class="text-center">
                            Outras Taxas
                          </th>
                          <th class="text-center">
                            Desconto
                          </th>
                          <th class="text-center">
                            Valor Total
                          </th>
                          <th class="text-center">
                            Pago
                          </th>
                          <th class="text-center">
                            Status
                          </th>
                          <th class="text-center" v-if="!fastOpcoesBoleto.detalhes">
                            Ações
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="fastTesourariaInvoice.parcelasLoading">
                        <tr>
                          <td
                            colspan="16"
                            class="text-center"
                          >
                            <span> <b-icon icon="gear-fill" animation="spin"/> Carregando parcelas</span>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else-if="fastTesourariaInvoice.parcelas.length">
                        <tr
                          v-for="(
                            parcela, index
                          ) in fastTesourariaInvoice.parcelas"
                          :key="index"
                        >
                          <td :class="fastTesourariaInvoice.parcela == parcela.parcela ? 'text-center table-info' : 'text-center'">
                            {{ parcela.parcela }}
                          </td>
                          <td :class="fastTesourariaInvoice.parcela == parcela.parcela ? 'text-center table-info' : 'text-center'">
                            {{ formataDataT(parcela.data_registro) }}
                          </td>
                          <td :class="fastTesourariaInvoice.parcela == parcela.parcela ? 'text-center table-info' : 'text-center'">
                            {{ formataDataT(parcela.data_vencimento) }}
                          </td>
                          <td :class="fastTesourariaInvoice.parcela == parcela.parcela ? 'text-center table-info' : 'text-center'">
                            R$ {{ formataPreco(parcela.valor_parcela) }} <br/>
                          </td>
                          <td :class="fastTesourariaInvoice.parcela == parcela.parcela ? 'text-center table-info' : 'text-center'">
                            R$ {{ formataPreco(parcela.outras_taxas) }} <br/>
                          </td>
                          <td :class="fastTesourariaInvoice.parcela == parcela.parcela ? 'text-center table-info' : 'text-center'">
                            R$ {{ formataPreco(parcela.outras_taxas) }} <br/>
                          </td>
                          <td :class="fastTesourariaInvoice.parcela == parcela.parcela ? 'text-center table-info' : 'text-center'">
                            R$ {{ formataPreco(parcela.taxa_cartao) }} <br/>
                          </td>
                          <td :class="fastTesourariaInvoice.parcela == parcela.parcela ? 'text-center table-info' : 'text-center'">
                            R$ {{ formataPreco(parcela.taxa_boleto) }} <br/>
                          </td>
                          <td :class="fastTesourariaInvoice.parcela == parcela.parcela ? 'text-center table-info' : 'text-center'">
                            R$ {{ formataPreco(parcela.outras_taxas) }} <br/>
                          </td>
                          <td :class="fastTesourariaInvoice.parcela == parcela.parcela ? 'text-center table-info' : 'text-center'">
                            R$ {{ formataPreco(parcela.descontos) }} <br/>
                          </td>
                          <td :class="fastTesourariaInvoice.parcela == parcela.parcela ? 'text-center table-info' : 'text-center'">
                            R$ {{ formataPreco(parcela.valor_parcela_total) }} <br/>
                          </td>
                          <td :class="fastTesourariaInvoice.parcela == parcela.parcela ? 'text-center table-info' : 'text-center'">
                            <button
                              v-if="parcela.pago"
                              class="btn btn-sm btn-success pt-0 pb-0"
                            >
                              <small>Sim</small>
                            </button>
                            <button
                              v-else
                              class="btn btn-sm btn-danger pt-0 pb-0"
                            >
                              <small>Não</small>
                            </button>
                          </td>
                          <td :class="fastTesourariaInvoice.parcela == parcela.parcela ? 'text-center table-info' : 'text-center'">
                            <span v-if="!parcela.path_boleto" class="text-secondary"><small>Boleto não gerado</small> </span>
                            <span v-else-if="parcela.cancelado_parcela" class="text-secondary"><small>Cancelado</small> </span>
                            <span v-else-if="parcela.pago" class="text-success"><small>Pago</small> </span>
                            <span v-else-if="verificaVencimento(parcela.data_vencimento)">                          
                              <small class="text-danger">Vencido</small>                           
                            </span>
                            <span v-else class="text-primary"><small>Pendente</small> </span>
                          </td>
                          <td 
                          :class="fastTesourariaInvoice.parcela == parcela.parcela ? 'text-center table-info' : 'text-center'"
                          v-if="!fastOpcoesBoleto.detalhes" 
                          >
                            <a title="EDITAR" v-if="fastTesourariaInvoice.parcela != parcela.parcela" href="#" class="text-dark mr-2" @click.prevent="exibeModalEditarOutroBoleto(parcela)"><b-icon aria-label="Editar" icon="pencil-square" font-scale="1"></b-icon></a>
                            <a title="GERAR BOLETO" :href="parcela.path_boleto" target="_blank" class="text-dark mr-2"><b-icon icon="file-earmark-pdf" font-scale="1" ></b-icon></a>
                            <a title="COPIAR URL BOLETO" href="#" @click.prevent="copyURLBoleto(parcela.path_boleto); exibeToasty('Boleto copiado para a Área de Transferência!','success')" target="_blank" class="text-dark"><b-icon icon="back" font-scale="1"></b-icon></a>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td
                            colspan="16"
                            class="text-center"
                          >
                            <span>Nenhuma parcela gerada</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-12 mt-3">
                <h5><b>Condições de pagamento</b></h5>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-1 mb-4">
                <label><small>Parcelamento</small></label>
                <select
                  disabled
                  class="form-control"
                  >
                  <option value="">1x</option>
                  <option value="">2x</option>
                  <option value="">3x</option>
                  <option value="">4x</option>
                  <option value="">5x</option>
                  <option value="">6x</option>
                  <option value="">8x</option>
                  <option value="">10x</option>
                  <option value="">12x</option>
                </select>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-2 mb-4">
                <label><small>Data de vencimento</small></label>
                <input
                  v-if="!fastTesourariaInvoice.pago && !fastTesourariaInvoice.cancelado_parcela && !fastOpcoesBoleto.detalhes"
                  v-model="fastTesourariaInvoice.data_vencimento"
                  @change="fastTesourariaInvoice.alteracoes_pendentes_vencimento = true"
                  type="date"
                  class="form-control"
                  maxlength="30"
                >
                <input
                  v-else
                  :value="fastTesourariaInvoice.data_vencimento"
                  type="date"
                  class="form-control"
                  readonly
                >
              </div>
              <div class="col-sm-12 col-md-12 col-lg-2 mb-4">
                <label><small>Forma de pagamento</small></label>
                <select
                  disabled
                  class="form-control"
                  >
                  <option value="">Boleto</option>
                  <option value="">Cartão de Crédito</option>
                  <option value="">Pix</option>
                </select>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-2 mb-4">
                <label><small>Conta de recebimento</small></label>
                <select
                  disabled
                  class="form-control"
                  >
                  <option value="">GerenciaNet</option>
                  <option value="">Asaas</option>
                  <option value="">Santander</option>
                </select>
              </div>
              <div v-if="!fastTesourariaInvoice.cancelado_parcela" class="col-sm-12 col-md-12 col-lg-1 text-center">
                <label><small>Pago</small></label>
                <div v-if="!fastTesourariaInvoice.alteracoes_pendentes_pago" class="fd-app-meus-cursos mt-0 ml-1">
                  <div class="fd-app-curso-aulas-list mt-0">
                    <div
                      class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center"
                    >
                      <label v-if="!fastTesourariaInvoice.pago && !fastOpcoesBoleto.detalhes" class="switch">
                        <input                                    
                          v-model="fastTesourariaInvoice.pago"
                          @change="exibeModalConfirmarBaixaManual()"
                          checked="checked"
                          type="checkbox"
                        >
                        <span class="slider round" />
                      </label>
                      <span v-else-if="!fastTesourariaInvoice.pago && fastOpcoesBoleto.detalhes" class="font-weight-bold text-primary">
                        Não
                      </span>
                      <span v-else class="font-weight-bold text-success">
                        <b-icon icon="check-circle" aria-hidden="true" font-scale="2"></b-icon> 
                      </span>
                    </div>
                  </div>
                </div>
                <div v-else class="mt-2 text-center">
                  <button class="btn btn-sm btn-primary blink_me" @click="editarInvoiceParcela('baixa_manual')">
                    <small>Confirmar baixa</small>
                  </button>
                </div>
              </div>
              <div v-if="fastTesourariaInvoice.cancelado_parcela" class="col-sm-12 col-md-12 col-lg-1 text-center">
                <label><small>Cancelado</small></label>
                  <span class="font-weight-bold text-danger">
                    <b-icon icon="x-circle" aria-hidden="true" font-scale="2"></b-icon> 
                  </span>
              </div>

              <div class="col-sm-12 col-md-12 col-lg-4 mb-4">
                <label v-if="fastTesourariaInvoice.data_cancelamento_parcela && fastTesourariaInvoice.id_usuario_cancelamento_parcela" >
                  <small>Detalhes cancelamento</small>
                </label>
                <label v-else-if="fastTesourariaInvoice.pago" >
                  <small>Detalhes pagamento</small>
                </label>
                <label v-else>
                  <small>Detalhes última edição</small>
                </label> 
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"> {{formataDataHoraT(fastTesourariaInvoice.data_cancelamento_parcela)}}</span>
                  </div>
                  <input readonly type="text" class="form-control" :value="'Usuário: #'+fastTesourariaInvoice.id_usuario_cancelamento_parcela">
                </div>
              </div>

              <div class="col-sm-12 col-md-12 col-lg-12">
                <h5><b>Previsão de recebimento</b></h5>
              </div>

              <div class="col-sm-12 col-md-12 col-lg-2 mb-4">
                <label><small>Vencimento previsto</small></label>
                <input
                  disabled
                  v-if="!fastTesourariaInvoice.pago && !fastTesourariaInvoice.cancelado_parcela"
                  v-model="fastTesourariaInvoice.data_vencimento"
                  @change="fastTesourariaInvoice.alteracoes_pendentes_vencimento = true"
                  type="date"
                  class="form-control"
                  maxlength="30"
                >
                <input
                  v-else
                  :value="fastTesourariaInvoice.data_vencimento"
                  type="date"
                  class="form-control"
                  readonly
                >
              </div>

              <div class="col-sm-12 col-md-12 col-lg-2 mb-4">
                <label><small>Juros</small></label>
                <money :value="(fastTesourariaInvoice.taxa_boleto + fastTesourariaInvoice.taxa_cartao)" @keyup.native="fastTesourariaInvoice.alteracoes_pendentes_parcela = true" class="form-control" readonly></money>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-2 mb-4">
                <label><small>Multa</small></label>
                <money :value="fastTesourariaInvoice.outras_taxas" @keyup.native="fastTesourariaInvoice.alteracoes_pendentes_parcela = true" class="form-control" readonly></money>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-2 mb-4">
                <label><small>Desconto</small></label>
                <money :value="fastTesourariaInvoice.desconto" @keyup.native="fastTesourariaInvoice.alteracoes_pendentes_parcela = true" class="form-control" readonly></money>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-2 mb-4">
                <label><small>Tarifa</small></label>
                <money :value="fastTesourariaInvoice.acrescimo" @keyup.native="fastTesourariaInvoice.alteracoes_pendentes_parcela = true" class="form-control" readonly></money>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-2 mb-4">
                <label><small>Valor a receber</small></label>
                <money :value="fastTesourariaInvoice.valor_parcela_total" :class="!fastTesourariaInvoice.valor_parcela_total ? 'form-control border border-danger' : 'form-control'" readonly></money>
              </div>      


                <div v-if="fastTesourariaInvoice.alteracoes_pendentes_vencimento" class="col-sm-12 col-md-12 col-lg-12 mb-4 text-center">
                  <button class="btn btn-sm btn-primary blink_me" @click="editarInvoiceParcela('data_vencimento')">
                    <small>Confirmar alteração</small>
                  </button>
              </div>

              <div class="col-sm-12 col-md-12 col-lg-6 mb-2 mt-2">
                <h4 class="m-0 text-dark font-weight-bold">
                    Parcela {{fastTesourariaInvoice.parcela}}/{{fastTesourariaInvoice.nr_parcelas}} 
                  </h4>
                  <div v-if="fastTesourariaInvoice.motivo_cancelamento_parcela">
                    <label class="font-weight-bold text-danger mt-3">Motivo do cancelamento: </label>&nbsp; <span class="text-secondary">{{fastTesourariaInvoice.motivo_cancelamento_parcela}}</span>
                  </div>
                  
                  <div v-if="!fastTesourariaInvoice.motivo_cancelamento_parcela || fastTesourariaInvoice.pago" class="mt-3 mb-3">       
                    <a v-if="fastTesourariaInvoice.aluno.telefone && fastTesourariaInvoice.path_boleto != 'Nenhum boleto registrado' && !fastTesourariaInvoice.cancelado_parcela" target="_blank" :href="retornaLinkWhatsBoleto(fastTesourariaInvoice.aluno.telefone, fastTesourariaInvoice.path_boleto, fastTesourariaInvoice.parcela, fastTesourariaInvoice.valor_parcela_total, fastTesourariaInvoice.data_vencimento, fastTesourariaInvoice.nome_plataforma, fastTesourariaInvoice.tipo)" class="btn btn-sm btn-success pb-0 pt-0">
                      <small><b-icon icon="share" aria-hidden="true"></b-icon> WhatsApp</small>                               
                    </a>
                    <label class="font-weight-bold text-secondary">&nbsp; Enviar ao aluno:</label>&nbsp; <span>{{fastTesourariaInvoice.aluno.telefone}}</span>
                    <br/>   
                    <a v-if="fastTesourariaInvoice.responsavel.telefone && fastTesourariaInvoice.path_boleto != 'Nenhum boleto registrado' && !fastTesourariaInvoice.cancelado_parcela" target="_blank" :href="retornaLinkWhatsBoleto(fastTesourariaInvoice.responsavel.telefone, fastTesourariaInvoice.path_boleto, fastTesourariaInvoice.parcela, fastTesourariaInvoice.valor_parcela_total, fastTesourariaInvoice.data_vencimento, fastTesourariaInvoice.nome_plataforma, fastTesourariaInvoice.tipo)" class="btn btn-sm btn-success pb-0 pt-0">
                      <small><b-icon icon="share" aria-hidden="true"></b-icon> WhatsApp</small>                                
                    </a>
                    <label class="font-weight-bold text-secondary">&nbsp; Enviar ao responsável:</label>&nbsp; <span>{{fastTesourariaInvoice.responsavel.telefone}}</span>
                  </div>
                  
              </div>
              <div class="col-sm-12 col-md-12 col-lg-6 mb-2 mt-2 text-right">

                <div v-if="fastTesourariaInvoice.cancelado_parcela">
                  <h4 class="text-danger font-weight-bold">Parcela cancelada</h4>

                </div>
                <div v-else-if="fastTesourariaInvoice.pago">
                  <h4 class="text-success font-weight-bold">Parcela paga</h4>   
                </div>
                <div v-else-if="verificaVencimento(fastTesourariaInvoice.data_vencimento)">
                  <h4 class="text-danger font-weight-bold">Parcela vencida</h4>   
                  <a v-if="!fastOpcoesBoleto.detalhes" href="#" class="btn btn-sm btn-danger mt-3" @click.prevent="showModal('modalCancelarParcela')">
                    <b-icon icon="x-octagon"></b-icon> <span>Cancelar parcela</span>
                  </a>
                </div>
                <div v-else>
                  <h4 class="text-warning">Pagamento pendente</h4>  
                  <a v-if="!fastOpcoesBoleto.detalhes" href="#" class="btn btn-sm btn-danger mt-3" @click.prevent="showModal('modalCancelarParcela')">
                    <b-icon icon="x-octagon"></b-icon> <span>Cancelar parcela</span>
                  </a>                          
                </div>

              </div>

              <div v-if="!fastTesourariaInvoice.cancelado_parcela && !fastOpcoesBoleto.detalhes" class="col-sm-12 col-md-12 col-lg-12 mb-4">
                <label class="font-weight-bold text-secondary">Comprovante:</label>&nbsp;
                <a v-if="fastTesourariaInvoice.path_comprovante_pagamento" :href="fastTesourariaInvoice.path_comprovante_pagamento" target="_blank" download class="btn btn-sm btn-success pb-0 pt-0">Download</a>

                <uploader
                  :file-status-text="statusText"
                  :options="optionsArquivo"
                  class="uploader-example"
                  @file-success="fileSuccess"
                  @file-added="fileValidation(fastTesourariaInvoice.id_invoice, fastTesourariaInvoice.parcela)"
                >
                  <uploader-unsupport />
                  <uploader-drop>
                    <p
                      v-if="fastTesourariaInvoice.path_comprovante_pagamento"
                      class="aluno_font_color"
                    >
                      Atualizar comprovante de pagamento
                    </p>
                    <p
                      v-else
                      class="aluno_font_color"
                    >
                      Inserir comprovante de pagamento
                    </p>
                    <uploader-btn
                      :attrs="restrictArquivo"
                    >
                      Clique aqui para selecionar
                    </uploader-btn>
                  </uploader-drop>
                  <uploader-list />
                </uploader> 

              </div>

            </div>

            <div v-if="fastTesourariaInvoice.tipo == 'I' || fastTesourariaInvoice.tipo == 'L'" class="row mt-4">
              <div class="col-12 mb-2">
                <h4 class="m-0 text-secondary">
                    Itens de cobrança
                  </h4>
              </div>
              <div class="col-12 table-responsive mt-2">
                <table class="table table-sm table-striped">
                  <thead class="thead-dark">
                    <tr class="text-center">
                      <th>
                        <small
                          class="font-weight-bold"
                        >Item de cobrança</small>
                      </th>
                      <th>
                        <small
                          class="font-weight-bold"
                        >Valor padrão</small>
                      </th>
                      <th>
                        <small
                          class="font-weight-bold"
                        >Quantidade</small>
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="fastTesourariaInvoice.itensLoading">
                    <tr>
                      <td
                        colspan="3"
                        class="text-center"
                      >
                      Carregando...
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else-if="fastTesourariaInvoice.itensCobranca.length">
                    <tr
                      v-for="e in fastTesourariaInvoice.itensCobranca"
                      :key="e.id_item_cobranca"
                    >
                      <td class="align-middle text-center">
                        {{ e.nome_item }}
                      </td>   
                      <td class="align-middle text-center">
                        R$ {{ formataPreco(e.valor_item) }}
                      </td>                              
                      <td class="align-middle text-center">
                        {{ e.quantidade }}
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td
                        colspan="3"
                        class="text-center"
                      >
                        Nenhum item encontrado
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            
          </div>
        </div>
      </div>
      <modal
      name="modalConfirmarBaixaManual"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Baixa manual</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalConfirmarBaixaManual')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <p class="text-info">
                A baixa manual deixará a parcela permanentemente definida como paga. A única opção de alteração que estará disponível após uma baixa manual será o upload do comprovante de pagamento.
              </p>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <button class="btn btn-danger mr-2" @click="hideModal('modalConfirmarBaixaManual'); fastTesourariaInvoice.alteracoes_pendentes_pago = true">
                OK, Estou ciente
              </button>
              <button class="btn btn-secondary mr-2" @click="hideModal('modalConfirmarBaixaManual'); fastTesourariaInvoice.pago = false">
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>
    </div>   
</template>
<script>
import { settings } from "@/config";
import methods from "@/methods";

export default {
    name: "DetalhesRecebivel",
    props: {
        id_plataforma: {
          type: Number,
          required: true,
        },
        id_invoice: {
          type: Number,
          required: true,
        },
        parcela: {
          type: Number,
          required: true,
        },
        tipo: {
          type: String,
          required: false,
        }
    },
    
    mixins: [methods],
    data: function () {
        return {
          modalWidthSm: this.isMobile() ? "80%" : "30%",
          modalWidthMd: this.isMobile() ? "90%" : "50%",
          modalWidthLg: this.isMobile() ? "100%" : "90%",

          testeIdInvoice: 123,
          testeIdPlataforma : 123,

          fastRecebiveis: {
          parcelas: [],
          
          loading: false
          },

          fastTesourariaInvoice: {
            ativo: "",
            cancelado_invoice: "",
            cancelado_parcela: "",
            cpf_aluno: "",
            cpf_responsavel: "",
            data_cancelamento_invoice: "",
            data_cancelamento_parcela: "",
            data_pagamento: "",
            data_registro: "",
            data_vencimento: "",
            descontos: "",
            id_usuario_cancelamento_invoice: "",
            id_usuario_cancelamento_parcela: "",
            id_invoice: "",
            id_matricula: "",
            id_plataforma: 0,
            logo_plataforma: "",
            nome_aluno: "",
            nome_plataforma: "",
            nome_responsavel: "",
            nr_parcelas: "",
            obs: "",
            pago: "",
            parcela: "",
            plataforma_mae: "",
            status_plataforma: "",
            subtotal: "",
            total: "",
            totalPago: "0",
            totalVencido: "0",
            totalCancelado: "0",
            valor_parcela_total: "",
            valor_parcela: "",
            taxa_boleto: "",
            taxa_cartao: "",
            outras_taxas: "",
            acrescimo: "",
            tipo: "",
            path_boleto: "",
            path_comprovante_pagamento: "",
            motivo_cancelamento_invoice: "",
            motivo_cancelamento_parcela: "",
            aluno: {
              id_usuario: "",
              id_pessoa: 0,
              email: "",
              first_name: "",
              last_name: "",
              cpf_cnpj: "",
              datanascimento_abertura: null,
              sexo: "",
              uf: "",
              cep: "",
              logradouro: "",
              bairro: "",
              cidade: "",
              complemento: "",
              numero: "",
              telefone: "",
              loading: true,
            },
            responsavel: {
              id_usuario: "",
              id_pessoa: 0,
              email: "",
              first_name: "",
              last_name: "",
              cpf_cnpj: "",
              datanascimento_abertura: null,
              sexo: "",
              uf: "",
              cep: "",
              logradouro: "",
              bairro: "",
              cidade: "",
              complemento: "",
              numero: "",
              telefone: "",
              loading: true,
            },
            itensCobranca: [],
            itensLoading: true,
            parcelasLoading: true,
            alteracoes_pendentes_parcela: false,
            alteracoes_pendentes_vencimento: false,
            alteracoes_pendentes_pago: false,
            parcelas: [],
          },

          fastOpcoesBoleto: {
            baixar : false,
            detalhes: false
          },
          fastErros: [],
          // Inconsistências
          fastErrosGerencianet: [],
          fastErrosGerencianetLoading: true,
          fastTesourariaInvoiceComissao: {
            total: [],
            loading: true
          },
          statusText: {
            success: "Sucesso",
            error: "Erro",
            uploading: "Fazendo upload...",
            paused: "Upload pausado",
            waiting: "Aguardando...",
          },
          optionsArquivo: {
            target: settings.endApiFastEad + "api/fast_tesouraria_invoice_parcela/atualiza_comprovante",
            testChunks: false,
            chunkSize: 1024 * 1024 * 100, // 100MB
            singleFile: true,
            query: {
              id_plataforma: this.$route.params.id_plataforma,
              id_invoice: 0,
              parcela: 0
            }        
          },
          restrictArquivo: {
            accept:
              "application/pdf,image/*",
          },
      
        }
    },
    methods: {
      consultaInvoiceDetalhes(){
        this.promiseGetFastApi("api/fast_tesouraria_invoice_parcela/lista_gestao_recebiveis", `PageIndex=1&PageSize=100&IdPlataforma=${this.id_plataforma}&IdInvoice=${this.id_invoice}`)
          .then((res) => {          
            this.fastRecebiveis.parcelas = res.Data;

            let boleto = res.Data.filter(parc => {
              return parc.parcela === this.parcela
            })[0]

            console.log("exibirDetalhes", boleto)
            this.fastLinkClipboard = false
            this.fastTesourariaInvoice.alteracoes_pendentes_pago = false
            this.fastTesourariaInvoice.alteracoes_pendentes_vencimento = false
            this.fastTesourariaInvoice.path_boleto = boleto.path_boleto ? boleto.path_boleto : "Nenhum boleto registrado"
            this.fastTesourariaInvoice.id_pessoa_aluno = boleto.id_pessoa_aluno
            this.fastTesourariaInvoice.id_pessoa_responsavel = boleto.id_pessoa_responsavel
            this.fastTesourariaInvoice.ativo = boleto.ativo
            this.fastTesourariaInvoice.cancelado_parcela = boleto.status_cancelado
            this.fastTesourariaInvoice.cancelado_invoice = boleto.cancelado_invoice
            this.fastTesourariaInvoice.cpf_aluno = boleto.cpf_aluno
            this.fastTesourariaInvoice.cpf_responsavel = boleto.cpf_responsavel
            this.fastTesourariaInvoice.data_pagamento = boleto.data_pagamento
            this.fastTesourariaInvoice.data_registro = boleto.data_registro 
            this.fastTesourariaInvoice.data_vencimento = boleto.data_vencimento ? boleto.data_vencimento.split("T")[0] : ""
            this.fastTesourariaInvoice.descontos = boleto.descontos
            this.fastTesourariaInvoice.id_invoice = boleto.id_invoice
            this.fastTesourariaInvoice.id_matricula = boleto.id_matricula
            this.fastTesourariaInvoice.id_plataforma = boleto.id_plataforma
            this.fastTesourariaInvoice.logo_plataforma = boleto.logo_plataforma
            this.fastTesourariaInvoice.nome_aluno = boleto.nome_aluno 
            this.fastTesourariaInvoice.nome_plataforma = boleto.nome_plataforma
            this.fastTesourariaInvoice.nome_responsavel = boleto.nome_responsavel
            this.fastTesourariaInvoice.nr_parcelas = boleto.nr_parcelas
            this.fastTesourariaInvoice.obs = boleto.obs
            this.fastTesourariaInvoice.pago = boleto.status_pago 
            this.fastTesourariaInvoice.parcela = boleto.parcela
            this.fastTesourariaInvoice.plataforma_mae = this.id_plataforma
            this.fastTesourariaInvoice.status_plataforma = boleto.status_plataforma
            this.fastTesourariaInvoice.subtotal = boleto.subtotal
            this.fastTesourariaInvoice.total = boleto.total
            this.fastTesourariaInvoice.valor_parcela_total = boleto.valor_com_juros
            this.fastTesourariaInvoice.tipo = boleto.tipo_pagamento
            this.fastTesourariaInvoice.path_comprovante_pagamento = boleto.path_comprovante_pagamento
            this.fastTesourariaInvoice.log_pagto_auditoria = boleto.log_pagto_auditoria
            this.fastTesourariaInvoice.motivo_cancelamento_invoice = boleto.motivo_cancelamento_invoice 
            this.fastTesourariaInvoice.motivo_cancelamento_parcela = boleto.motivo_cancelamento_parcela
            this.fastTesourariaInvoice.data_cancelamento_invoice = boleto.data_cancelamento_invoice
            this.fastTesourariaInvoice.data_cancelamento_parcela = boleto.data_cancelamento_parcela 
            this.fastTesourariaInvoice.id_usuario_cancelamento_invoice = boleto.id_usuario_cancelamento_invoice 
            this.fastTesourariaInvoice.id_usuario_cancelamento_parcela = boleto.id_usuario_cancelamento_parcela 
            this.fastTesourariaInvoice.valor_parcela = boleto.valor_parcela 
            this.fastTesourariaInvoice.taxa_boleto = boleto.taxa_boleto 
            this.fastTesourariaInvoice.taxa_cartao = boleto.taxa_cartao 
            this.fastTesourariaInvoice.outras_taxas = boleto.outras_taxas 
            this.fastTesourariaInvoice.acrescimo = boleto.acrescimo
            // Busca itens de cobrança
            if (this.fastTesourariaInvoice.tipo == "I" || this.fastTesourariaInvoice.tipo == "L") {
              this.fastTesourariaInvoice.itensLoading = true
              this.promiseGetFastApi("api/fast_tesouraria_invoice_itens_cobranca/lista", `id_invoice=${boleto.id_invoice}&id_plataforma=${this.$route.params.id_plataforma}`)
              .then((res) => {
                //console.log("api/fast_tesouraria_invoice_itens_cobranca/lista", res)  
                if (res.length) {
                  this.fastTesourariaInvoice.itensCobranca = res
                } else {
                  this.fastTesourariaInvoice.itensCobranca = []
                }
                this.fastTesourariaInvoice.itensLoading = false
              })
              .catch(e => {
                this.exibeToasty(e, "error")
                this.fastTesourariaInvoice.itensLoading = false
              })
            } else {
              this.fastTesourariaInvoice.itensCobranca = []
            }
            // Dados do aluno
            if (this.fastTesourariaInvoice.id_pessoa_aluno){
              this.fastTesourariaInvoice.aluno.loading = true
              this.promiseGetFastApi("api/fast_plataforma_usuario/busca_pessoa_secretaria", `id_pessoa=${this.fastTesourariaInvoice.id_pessoa_aluno}&id_plataforma=${this.$route.params.id_plataforma}`)
              .then((res) => {
                //console.log("api/fast_plataforma_usuario/busca_pessoa_secretaria", res)  
                let obj = JSON.parse(res);
                if (!obj.error) {
                  obj.loading = false
                  this.fastTesourariaInvoice.aluno = obj
                } else {
                  this.exibeToasty("Erro ao busca informações do responsável", "error")
                  this.fastTesourariaInvoice.aluno = {
                    id_usuario: "",
                    id_pessoa: 0,
                    email: "",
                    first_name: "",
                    last_name: "",
                    cpf_cnpj: "",
                    datanascimento_abertura: null,
                    sexo: "",
                    uf: "",
                    cep: "",
                    logradouro: "",
                    bairro: "",
                    cidade: "",
                    complemento: "",
                    numero: "",
                    telefone: "",
                    loading: false,
                  }
                }
                this.fastTesourariaInvoice.aluno.loading = false
              })
              .catch(e => {
                this.exibeToasty(e, "error")
                this.fastTesourariaInvoice.aluno.loading = false
              })
            } else {
              this.exibeToasty("Id_pessoa não registrado do aluno", "error")
              this.fastTesourariaInvoice.aluno = {
                id_usuario: "",
                id_pessoa: 0,
                email: "",
                first_name: "",
                last_name: "",
                cpf_cnpj: "",
                datanascimento_abertura: null,
                sexo: "",
                uf: "",
                cep: "",
                logradouro: "",
                bairro: "",
                cidade: "",
                complemento: "",
                numero: "",
                telefone: "",
                loading: false,
              }
            }

            // Dados do responsável
            if (this.fastTesourariaInvoice.id_pessoa_responsavel){
              this.fastTesourariaInvoice.responsavel.loading = true
              this.promiseGetFastApi("api/fast_plataforma_usuario/busca_pessoa_secretaria", `id_pessoa=${this.fastTesourariaInvoice.id_pessoa_responsavel}&id_plataforma=${this.$route.params.id_plataforma}`)
              .then((res) => {
                //console.log("api/fast_plataforma_usuario/busca_pessoa_secretaria", res)  
                let obj = JSON.parse(res);
                if (!obj.error) {
                  obj.loading = false
                  this.fastTesourariaInvoice.responsavel = obj
                } else {
                  this.exibeToasty("Erro ao busca informações do responsável", "error")
                  this.fastTesourariaInvoice.responsavel = {
                    id_usuario: "",
                    id_pessoa: 0,
                    email: "",
                    first_name: "",
                    last_name: "",
                    cpf_cnpj: "",
                    datanascimento_abertura: null,
                    sexo: "",
                    uf: "",
                    cep: "",
                    logradouro: "",
                    bairro: "",
                    cidade: "",
                    complemento: "",
                    numero: "",
                    telefone: "",
                    loading: false,
                  }
                }
                this.fastTesourariaInvoice.responsavel.loading = false
              })
              .catch(e => {
                this.exibeToasty(e, "error")
                this.fastTesourariaInvoice.responsavel.loading = false
              })
            } else {
              this.exibeToasty("Id_pessoa não registrado do responsável", "error")
              this.fastTesourariaInvoice.responsavel = {
                id_usuario: "",
                id_pessoa: 0,
                email: "",
                first_name: "",
                last_name: "",
                cpf_cnpj: "",
                datanascimento_abertura: null,
                sexo: "",
                uf: "",
                cep: "",
                logradouro: "",
                bairro: "",
                cidade: "",
                complemento: "",
                numero: "",
                telefone: "",
                loading: false,
              }
            }
            // Dados de comissão
            this.fastTesourariaInvoiceComissao.loading = true      
            this.promiseGetFastApi("api/fast_tesouraria_invoice_schema_comissao/lista", `id_plataforma=${this.$route.params.id_plataforma}&id_invoice=${boleto.id_invoice}`)
            .then((res) => {
              if (res.length) {
                this.fastTesourariaInvoiceComissao.total = res
                this.exibeToasty("Essa fatura possui comissão", "info")
              } else {
                this.fastTesourariaInvoiceComissao.total = []
              }
              this.fastTesourariaInvoiceComissao.loading = false          
            })
            .catch(e => {
              this.exibeToasty(e, "error")
              this.fastTesourariaInvoiceComissao.loading = false
            })
            this.getParcelasInvoice(boleto.id_invoice)
            //this.alteraFastNavegacao('editarBoleto')
            // Retorna inconsistências fastead/gerencianet
            this.getInconsistenciasGerenciaNet(this.fastTesourariaInvoice.id_plataforma, boleto.id_invoice, boleto.parcela)


          })     

      },
      async getParcelasInvoice(id_invoice){
        this.fastTesourariaInvoice.parcelasLoading = true
        this.promiseGetFastApi("api/fast_tesouraria_invoice_parcela/lista", `id_invoice=${id_invoice}`)
        .then((res) => {
          //console.log("api/fast_tesouraria_invoice_parcela/lista", res)  
          if (res.length) {
            this.fastTesourariaInvoice.parcelas = res
            //Pega valores totais de cada status do invoice 
            this.fastTesourariaInvoice.totalCancelado = res.filter(cancelado => cancelado.cancelado_parcela).reduce((total, cancelado) => total + cancelado.valor_parcela_total, 0)
            this.fastTesourariaInvoice.totalPago = res.filter(pago => pago.pago && !pago.cancelado_parcela).reduce((total, pago) => total + pago.valor_parcela_total, 0)
            this.fastTesourariaInvoice.totalVencido = res.filter(vencido => !vencido.pago && !vencido.cancelado_parcela && new Date(vencido.data_vencimento) < new Date()).reduce((total, vencido) => total + vencido.valor_parcela_total, 0);
      
          } else {
            this.fastTesourariaInvoice.parcelas = []
          }
          this.fastTesourariaInvoice.parcelasLoading = false
        })
        .catch(e => {
          this.exibeToasty(e, "error")
          this.fastTesourariaInvoice.parcelasLoading = false
        })
      },
      retornaTotalComissao(){
      let retorno = ""
      let valor = 0.00
      this.fastTesourariaInvoiceComissao.total.forEach(e => {
        valor += e.valor
      })
      if (valor > 0) {
        retorno = ` - R$ ${this.formataPreco(valor)} (comissão) = R$ ${this.formataPreco(this.fastTesourariaInvoice.total - valor)}`
      }
      return retorno
      },
      verificaVencimento(dataVencimento){
        let dataAtual = this.$store.state.fastDataAtualServidor.toLocaleDateString('pt-br').split( '/' ).reverse( ).join( '-' )   
        if (dataVencimento) {
          dataVencimento = new Date(dataVencimento.split("T")[0]).toISOString().slice(0,10);   
        }
        if (dataAtual > dataVencimento) {
          return true
        }
        else {
          return false
        }
      },
      retornaParcelaComissao(parcela){
        let retorno = ""
        let valor = 0.00
        this.fastTesourariaInvoiceComissao.total.forEach(e => {
          if (e.parcela == parcela.parcela) {
            valor += e.valor
          }
        })
        if (valor > 0) {
          retorno = ` - R$ ${this.formataPreco(valor)} (comissão)`
        }
        return retorno
      },
      retornaLinkWhats(telefone){
        let link = ""
        let tel = telefone
        tel = telefone.replaceAll("-", "").replaceAll("(", "").replaceAll(")", "").replaceAll(" ", "")
        tel = `55${tel}`
        link = `https://api.whatsapp.com/send?phone=${tel}`
        return link
      },
      retornaLinkWhatsBoleto(telefone, path_boleto, parcela, valor_parcela_total, data_vencimento, nome_plataforma, tipo){
        let link = ""
        let tel = telefone
        if (tel) {
          tel = telefone.replaceAll("-", "").replaceAll("(", "").replaceAll(")", "").replaceAll(" ", "")
          tel = `55${tel}`
          if (path_boleto) {
            let text = path_boleto
            if (parcela && valor_parcela_total && data_vencimento && nome_plataforma && tipo) {
              let referente = ""
              if (tipo == "I") {
                referente = "à cobrança"
              } else if (tipo == "M"){
                referente = "à taxa de matrícula"
              } else if (tipo == "C"){
                referente = "ao curso definido"
              } else {
                referente = "à cobrança"
              }
              text = `Olá, segue a parcela ${parcela} do carnê no valor de R$ ${this.formataPreco(valor_parcela_total)}. Cobrança referente ${referente}, emitida por ${nome_plataforma}, com vencimento para ${this.formataDataT(data_vencimento)}. Acesse o boleto pelo link: ${path_boleto}`
            }
            link = `https://api.whatsapp.com/send?phone=${tel}&text=${text}`
          } else {
            this.exibeToasty("Boleto não cadastrado", "error")
          }
        } else {
          this.exibeToasty("Telefone não registrado", "error")
        }
        return link
      },
      fileSuccess(rootFile, file, message, chunk) {
        let result = JSON.parse(JSON.parse(message));
        
        if (!result.error) {
          console.log(result)
          this.fastTesourariaInvoice.path_comprovante_pagamento = result.url
          this.getInvoiceBoletos(this.$route.params.id_plataforma, this.fastAnoAtual, this.fastMesAtual, '' , '')
          this.exibeToasty("Comprovante salvo com sucesso", "success")
        } else {
          this.exibeToasty(result.error, "error")
        }
      },
      async getInconsistenciasGerenciaNet(id_plataforma, id_invoice, parcela){
        this.fastErrosGerencianetLoading = true
        this.fastErrosGerencianet = []
        this.promiseGetFastApi("api/fast_tesouraria_invoice_parcela/verifica_inconsistencias", `id_plataforma=${id_plataforma}&id_invoice=${id_invoice}&parcela=${parcela}`)
        .then((res) => {
          let json = JSON.parse(res)
          if (json.erros) {
            if (json.erros.split(";").length) {
              this.fastErrosGerencianet = json.erros.split(";")
            } else {
              this.fastErrosGerencianet.push(json.erros)
            }
          }
          if (json.error) {
            this.fastErrosGerencianet.push(json.description)
          }
          this.fastErrosGerencianetLoading = false
          console.log("api/fast_tesouraria_invoice_parcela/verifica_inconsistencias", json)          
        })
        .catch(e => {
          this.exibeToasty(e, "error")
          this.fastErrosGerencianetLoading = false
        })
      },
      exibeModalConfirmarBaixaManual(){
        this.showModal('modalConfirmarBaixaManual') 
      },
    
    },
    mounted() {
      this.consultaInvoiceDetalhes();
      
      if(this.tipo === 'editar' || this.tipo === ''){
        this.fastOpcoesBoleto.baixar = ''
        this.fastOpcoesBoleto.detalhes = ''
      }else if(this.tipo === 'baixar'){
        this.fastOpcoesBoleto.baixar = true
        this.fastOpcoesBoleto.detalhes = ''
      }else if(this.tipo === 'detalhes'){
        this.fastOpcoesBoleto.baixar = ''
        this.fastOpcoesBoleto.detalhes = true
      }
    }
}
</script>
<style scoped>
</style>